export default {
    changeLangModal:{
        "title":'تغییر زبان',
        "body":'زبان خود را انتخاب کنید',
        "FA":'فارسی',
        "EN":'انگلیسی'
    },
    navbar:{
        "changeLang":'تغییر زبان',
        "homeBtn":'صرافی بلاک کارنسی',
        "market":'بازارها',
        "fee":'کارمزد',
        "support":'پشتیبانی',
        "blog":'بلاگ',
        "p2p":'معامله حرفه ای',
        "exchange":'صرافی آنلاین',
        "login":'ورود',
        "signup":'ثبت نام',
        "tickets":'تیکت',
        "notifications":'اعلانات',
        "dashboard":'داشبورد',
        "account":'حساب کاربری',
        "validation":'احراز هویت',
        "exit":'خروج'
    },
    homePageBanner:{
        "zeenCurrency":'بلاک کارنسی',
        "title":'بازار معاملاتی ارز های دیجیتال',
        "explanation":'خرید و فروش بیت کوین و سایر ارز های دیجیتال را در محیطی امن و حرفه ای تجربه کنید',
        "startBtn":'شروع کنید',
        
    },
    marketTable:{
        "toman":'تومان',
        "tether":'تتر',
        "changes":'تغییرات',
        "worldPrice":'قیمت جهانی',
        "buyPrice":'قیمت خرید',
        "sellPrice":'قیمت فروش',
        "graph":'نمودار',
        "buy":'خرید',
        "sell":'فروش',
        "opration":'عملیات',
        "moreCoins":'مشاهده همه ارز ها',
    },
    cards:{
        "title":'امکانات و مزایای ما',
        "explanation":'امکانات منحصر به فرد سایت بلاک کارنسی باعث ایجاد تجربه ای متفاوت از ارز های دیجیتال شده است',
        "fastDeal":'معاملات سریع',
        "secure":'امن و مطمئن',
        "support":'پشتیبانی ۲۴ ساعته'
    },
    steps:{
        "title":'مراحل انجام کار',
        "signup":'ثبت نام در سایت',
        "signupBody":'تنها با داشتن آدرس ایمیل میتوانید در سایت بلاک کارنسی ثبت نام اولیه انجام دهید و با امکانات و قابلیت های سایت آشنا شوید',
        "validation":'احراز هویت',
        "validationBody":'پس از ثبت نام اولیه با وارد کردن اطلاعات فردی و اطلاعات بانکی خود و همچنین یک تصویر از مدارک خود میتواند به سادگی و در کمترین زمان ممکن به تمامی امکانات سایت دسترسی پیدا کنید و معاملات خود را انجام دهید',
        "wallet":'شارژ کیف پول',
        "walletBody":'پس از احراز هویت در سایت به تمامی امکانات سایت دسترسی خواهید داشت و با شارژ کردن کیف پول خود میتوانید شروع به معامله کنید',
        "start":'شروع معامله ',
        "startBody":'بلاک کارنسی با پشتیبانی بیش از ۳۰ ارز متفاوت این امکان را به شما کاربران عزیز می دهد تا با کمترین محدودیت معاملات خود را انجام دهید',
    },
    statistic:{
        "dealValue":'ارزش کل معاملات',
        "doneDeal":'معامله ی انجام شده',
        "users":'کاربر فعال',
    },
    Faq:{
        "title":'سوالات متداول'
    },
    footer:{
        "explanation":'امکانات منحصر به فرد سایت بلاک کارنسی باعث ایجاد تجربه ای متفاوت از ارز های دیجیتال شده است',
        "vista":'تمامی حقوق شرکت ویستا محفوظ است',
        "aboutUs":'درباره ما',
        "support":'پشتیبانی',
        "blog":'بلاگ',
        "termsAndConditions":'قوانین و شرایط',
        "faq":'سوالات متداول',
        "quickAccess":'دسترسی سریع',
        "contactUs":'تماس با ما'
    },
    aboutUs:{
        "title":'درباره صرافی بلاک کارنسی',
        "explanation":'مجموعه بلاک کارنسی  با بهره گیری از تجربه چندین ساله در زمینه خرید و فروش ارزهای الکترونیک و رمز ارزها و با تکیه بر دانش مدیران ، آخرین فناوری ها و جدیدترین سیستم های مالی ، همواره در تلاش است تا راه حل های انعطاف پذیر و متناسب با خواسته ها و نیازهای کاربران را ارائه نماید. مهمترین ویژگی ، قابلیت پردازش آنلاین و خودکار سفارشات خرید و فروش با ساده ترین و ایمن ترین شکل ممکن در تمامی ساعت شبانه روز است. بر این اساس بالاترین سرعت و بهترین کیفیت خدمات رسانی را برای کاربران ایرانی تضمین می کند.',
        // "explanation":'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.',
        "conclusion":'تنها با داشتن آدرس ایمیل میتوانید در سایت بلاک کارنسی ثبت نام اولیه انجام دهید و با امکانات و قابلیت های سایت آشنا شوید',
    },
    contactUs:{
        "explanation":'مجموعه بلاک کارنسی با بهره گیری از تجربه چندین ساله در زمینه خرید و فروش ارزهای الکترونیک و رمز ارزها و با تکیه بر دانش مدیران ، آخرین فناوری ها و جدیدترین سیستم های مالی ، همواره در تلاش است تا راه حل های انعطاف پذیر و متناسب با خواسته ها و نیازهای کاربران را ارائه نماید. مهمترین ویژگی ، قابلیت پردازش آنلاین و خودکار سفارشات خرید و فروش با ساده ترین و ایمن ترین شکل ممکن در تمامی ساعت شبانه روز است. بر این اساس بالاترین سرعت و بهترین کیفیت خدمات رسانی را برای کاربران ایرانی تضمین می کند.',
        // "explanation":'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.',
        "social":'مارا در شبکه های اجتماعی دنبال کنید',
    },
    fee:{
        "title":'کارمزد ها',
        "feeTermsTitle":'کارمزد معاملات بلاک کارنسی به صورت درصد از مبلغ کل معامله است و محاسبه آن بر اساس ملاحظات زیر صورت میگیرد :',
        "term1":'کسر کارمزد فروشنده ارز دیجیتال از مبلغ دریافتی به تومان کسر میشود و کارمزد خریدار ارز دیجیتال به صورت ارز دیحیتال',
        "term2":'کارمزد از هر دو سمت معامله کسر میشود',
        "term3":'در هنگام ثبت معاملات در والکس کارمزد به شما نمایش داده میشود',
        "levelsTitle":'سطوح کاربری و کارمزد معاملات',
        "levelsBody":'با توجه به معاملات ۳ ماه اخیر کاربر ، کارمزد معاملات طبق جدول زیر به صورت پلکانی محاسبه میگردد :',
        "coinFeeTitle":'کارمزد برداشت عرض ها',
        "coinFeeBody":'کارمزد برداشت دارایی های دیجیتال به نوع ارز بستگی دارد . این هزینه در واقع به دلیل کارمزد های تراکنش شیکه کسر میشود و بلاک کارنسی در آن نقشی ندارد . در حال حاضر این هرینه ها به شرح زیر است:',
        "rialFeeTitle":'کارمزد برداشت ریالی',
        "rialFeeBody":'برداشت ریالی برای تمامی حساب ها، در قالب انتقال پایا و یک بار در روز خواهد بود. بدیهی است مدت زمان انجام تسویه ریالی، بسته به زمان درخواست برداشت، در روز های غیر تعطیل میتواند حداکثر تا ۴۲ ساعت به طول انجامد',
        "fee":'کارمزد',
        "last3MounthTrade":'میزان معاملات در ۳ ماه اخیر',
        "userLevel":'سطح کاربری',
        "userLevels":'سطوح کاربری',
        "coin":'نوع ارز',
        "minWithDrawal":'حداقل میزان برداشت',
    },
    market:{
       "search":'جستجو',
    },
    privacyPolicy:{
        "title":'قوانین و شرایط',
        "explanation":'با انجام معامله با بلاک کارنسی، شما تصدیق می‌کنید که شرایط و قوانین را به طور کامل مطالعه کرده‌اید و با این شرایط خدمات موافقت کرده‌اید.بلاک کارنسی تابع قوانین جمهوری اسلامی ایران بوده و بستری برای تبادل دارایی ارزدیجیتال با ریال است و هیچ‌گونه تبادل ارزی اعم از خرید و فروش دلار یا سایر ارزهای کاغذی، در این بستر صورت نمی‌گیرد.بلاک کارنسی این اطمینان را به کاربران می‌دهد تا از اطلاعات شخصی کاربران به طور کامل محافظت کند و محیط امنی را برای مبادلات آنلاین فراهم کند.بلاک کارنسی ، هیچ‌گونه مسئولیتی در قبال نحوه‌ی معاملات کاربر و سود و زیان حاصل از آن نداردکاربر می‌پذیرد که به جز در موارد مورد تعهد بلاک کارنسی، حق هیچ گونه داعیه، طلب و شکایت از مجموعه بلاک کارنسی، مدیران، کارمندان و افراد مرتبط با این وب‌سایت را نخواهد داشت.',
        // "explanation":'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.',
        "secondTitle":'قوانین و شرایط',
        // "body":'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.',
        "body":'کاربر موظف است اطلاعات حساب بانکی خود را جهت معامله ارائه نماید و از ارائه کردن اطلاعات حساب فرد دیگری خودداری نماید.مواردی مانند درج شماره کارت و حساب بانکی صحیح برعهده کاربر است و هرگونه اشتباه در آن به عهده کاربر است.کاربر لازم است از کارت بانکی رسمی که به‌ نام شخص صاحب حساب در سیستم بانکی کشور ثبت شده است، نسبت به خرید ارزدیجیتال  از بلاک کارنسی اقدام نماید.'
    },
    successModal:{
        "title":'درخواست شما با موفقیت انجام شد',
        "body":'حساب کاربری شما پس از بررسی اطلاعات توسط تیم بلاک کارنسی فعال خواهد شد'
    },
    signUp:{
        "title":'ساخت حساب کاربری',
        "userName":'آدرس ایمیل',
        "passWord":'گذرواژه',
        "inviteCode":'کد دعوت',
        "acceptTerms":'با ثبت نام در بلاک کارنسی شما شرایط و قوانین استفاده از سرویس های سایت را می پذیرید',
        "signUpBtn":'ثبت نام',
        "googleBtn":'ادامه با حساب گوگل',
        "loginTitle":'حساب کاربری دارید',
        "loginLink":'وارید شوید',
        "Help":'راهنمای ثبت نام',
    },
    login:{
        "title":'ورود به حساب کاربری',
        "userName":'آدرس ایمیل',
        "passWord":'گذرواژه',
        "forgetPass":'فراموشی رمز عبور',
        "loginBtn":'ورود',
        "googleBtn":'ادامه با حساب گوگل',
        "signUpTitle":' حساب کاربری ندارید ؟',
        "signUpLink":'ثبت نام کنید',
    },
    otpEmail:{
        "title":'تایید ادرس ایمیل',
        "body":'کد شش رقمی ارسال شده به ایمیل خود را وارد نمایید',
        "btn":'تایید',
        "modal":'در حال انجام عملیات ...'
    },
    loginOtpEmail:{
        "title":'شناسایی دو عاملی',
        "body1":'کد ۶ رقمی ارسال شده به ایمیل',
        "body2":'را وارد کنید',
        "google":'کد تایید دو مرحله ای که برنامه Google Athenticator تولید کرده است را وارد کنید',
        "submit":'تایید',
        "seconds":'ثانیه',
        "reSend":'ارسال مجدد',
    },
    forgotPass:{
        "title":'فراموشی رمز عبور',
        "body":'آدرس ایمیلی که با آن در بامبو ثبت نام کرده اید را وارد کنید',
        "userName":'آدرس ایمیل',
        "btn":'تایید'
    },
    forgotPassNew:{
        "title":'تعیین رمز عبور جدید',
        "body":'رمز عبور جدید خود را وارد نمایید',
        "passWord":'رمز عبور',
        "seconds":'ثانیه',
        "reSend":' ارسال مجدد کد',
        "passwordRepeat":'تکرار رمز عبور ',
        "btn":'ثبت'
    },
    otpNewPassword:{
        "title":'فراموشی رمز عبور',
        "body":'کد شش رقمی ارسال شده به ایمیل خود را وارد نمایید',
        "btn":'تایید',
        "modal":'در حال انجام عملیات ...'
    },
    formsError:{
        "blanckEmail":'ایمیل اجباری',
        "truemail":'ایمیل صحیح را وارد کنید',
        "blanckPassWord":'گذرواژه اجباری ست',
        "minPass":'حداقل 8 کاراکتر',
        "wrongCombination":'ترکیبی از حروف کوچک و بزرگ و عدد',
        "wrongRepeat":'تکرار رمز عبور اشتباه است',
        "successChange":'رمز عبور با موفقیت تغییر یافت'
    },
    sidebar:{
        "dashboard":'داشبورد',
        "activeOrders":'سفارشات فعال',
        "account":'حساب کاربری',
        "wallet":'کیف پول',
        "exchangeWallet":'کیف پول صرافی',
        "history":'تاریخچه',
        "setting":'تنظیمات',
        "notifications":'اعلانات',
        "inviteFriends":'دعوت از دوستان',
        "tickets":'تیکت',
        "exit":'خروج از حساب ',
    },
    dashboardalert:{
        "title":'عنوان اعلام نمایشی به کاربر',
        "body":'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد،'
    },
    dashboardAccess:{
        "friendsTitle":'تعداد دوستان من',
        "personNum":'نفر',
        "totalBenefits":'مجموع سود من',
        "coinType":'تومان',
        "friendsBtn":'دعوت از دوستان',
        "accountError":'تکمیل اطلاعات کاربری',
        "accountBankError":'تکمیل اطلاعات بانکی',
        "accountLevel":'سطح کاربری'
    },
    dashboardOrders:{
        "orderHistory":'تاریخچه سفارشات',
        "activeOrders":'سفارشات فعال',
        "showBtn":'مشاهده',
        "inProgrres":'در حال بررسی',
        "done":'انجام شده',
        "canceled":'لغو شده',
        "buy":'خرید',
        "sell":'فروش',
        "bitcoin":'بیت کوین',
        "channel":'شبکه ارسال',
        "exchange":'صرافی',
        "promarket":'بازار-معاملات حرفه ای',
        "limit":'حد-معاملات حرفه ای',
        "tableDone":'اتمام شده',
        "tablePrice":'قیمت',
        "tableTotalPrice":'مجموع',
        "tableAmount":'مقدار',
    },
    walletTable:{
        "remain":'موجودی',
        "changes":'تغییرات',
        "value":'(تومان)ارزش',
        "inDeal":'در معامله',
        "depositAble":'قابل برداشت',
        "seeAll":'مشاهده همه ارز ها'
    },
    bankCards:{
        "remain":'موجودی',
    },
    apexChart:{
        "property":'Properties',
    },
    activeOrderBtns:{
        "market":'صرافی',
        "proMarket":'معاملات حرفه ای'
    },
    activeOrdersHeaderTable:{
        "amount":'مقدار',
        "value":'(تومان)ارزش',
        "price":'قیمت',
        "changes":'تغییرات',
        "inDeal":'در معامله',
        "time":'زمان'
    },
    activeOrdersMarektTable:{
            "market":'بازار',
            "coin":'نوع',
            "price":'قیمت',
            "amount":'مقدار',
            "total":'مجموع',
            "channel":'شبکه ارسال',
            "time":'زمان',
            "opration":'عملیات',
    },
    activeOrdersProMarektTable:{
        "allOrders":'همه سفارشات',
        "coins":'رمز ارز',
        "toman":'تومان',
        tether:'تتر',
        "done":'انجام شده',
        "coin":'نوع',
        "price":'قیمت',
        "amount":'مقدار',
        "total":'مجموع',
        "opration":'عملیات',
        "isPending":'در حال بررسی',
        "cancel":'لغو سفارش',
        "LIMITED_BUY":'خرید - حد',
        "LIMITED_SELL":'فروش - حد',
        "MARKEY_BUY":'خرید - بازار',
        "MARKET_SELL":'فروش - بازار',
    },
    account:{
        "personalInfoTitle":'اطلاعات شخصی',
        "name":'نام',
        "family":'نام خانوادگی',
        "nationalCode":'کد ملی',
        "birthDay":'تاریخ تولد',
        "phonenumber":'شماره تلفن همراه',
        "telephone":'شماره تلفن ثابت',
        "addres":'آدرس',
        "bankInfoTitle":'اطلاعات بانکی',
        "newCard":'افزودن حساب جدید',
        "bankName":'نام بانک',
        "shebaCode":'شماره شبا',
        "cartNumber":'شماره کارت',
        "deletCard":'حذف',
        "validationTitle":'احراز هویت',
        "validationHelp1":'لطفا تصویر کارت ملی را در کنار چهره خودتان همراه با یک دست نوشته به صورت کاملا واضح و خوانا دقیقا مانند تصویر نمونه ارسال نمایید',
        "validationHelp2":' دقت شود متن دست نوشته نبایستی تایپ شودهمچنین توجه داشته باشیدهنگام تهیه تصویر، متن دست نوشته و اطلاعات کارت ملی به صورت برعکس و آینه ای ثبت نشود',
        "validationHandWrite1":'متن دست نوشته :',
        "validationHandWrite2":'اینجانب (نام و نام خانوادگی) به کدملی(کد ملی) ضمن مطالعه و تایید قوانین استفاده از خدمات بلاک کارنسی، متعهد میگردم که حساب کاربری و مدارک خود را به منظور خرید و فروش ارز های دیجیتال در اختیار سایر افراد قرار ندهم.',
        "validationHandWrite3":'جهت احراز هویت در ساییت زین کارنسی',
        "validationHandWrite4":'نام و نام خانوادگی امضا و تاریخ',
        "picUploadBtn":'آپلود عکس سلفی',
        "editBtn":'ویرایش اطلاعات',
        "submit":'ثبت و ارسال اطلاعات',
        "edit":'ویرایش و ارسال اطلاعات'
    },
    wallet:{
        "remain":'موجودی',
        "changes":'تغییرات',
        "value":'(تومان)ارزش',
        "inDeal":'در معامله',
        "depositAble":'قابل برداشت',
        "opration":'عملیات',
        "deposit":'واریز',
        "withDrawal":'برداشت',
        "deal":'معامله',
        "tomanWallet":'کیف پول تومان',
    },
    walletSellModal:{
        "title":'برداشت از کیف پول تومان',
        "priceType":'مبلغ به تومان',
        "balance":'موجودی :',
        "cardNum":'شماره کارت',
        "addCard":'اضافه کردن کارت بانکی',
        "warning1":'درخواست های تسویه ریالی در روزهای تعطیل به دلیل تعطیلی بانک ها و عدم فعالیت سیستم پایا و ساتنا انجام نمیشود',
        "warning2":'درخواست های تسویه بالای ۱۰۰ میلیون تومان به دلیل محدودیت های بانکی حداکثر تا یک روز آینده کاری از زمان ثبت درخواست انجام خواهد شد',
        "btn":'ثبت درخواست برداشت',
        "emptyPrice":'قیمت را وارد کنید',
        "emptyCard":'کارت را انتخاب کنبد',
        "lowBalance":'موجودی کافی نیست',
        "toman":'تومان',
    },
    walletChargeModal:{
     "title":'واریز به کیف پول تومان',
     "body":'جهت افزایش اعتبار کیف پول ریالی خود با استفاده از کارت های بانکی عضو شتاب و از طریق درگاه پرداخت، حتما باید از کارت های بانکی به اسم خودتان که در پروفایلتان ثبت و تایید شده است استفاده نمایید',   
     "input":'مبلغ به تومان',
     "newCard":'افزودن حساب جدید',
     "btn":'انتقال به درگاه پرداخت',
    },
    walletCoinSellModal:{
        "title":'برداشت از کیف پول',
        "priceInput":'مقدار ',
        "remain":'موجودی :',
        "walletAdress":'آدرس کیف پول',
        "body":'در وارد کردن آدرس کیف پول خود دقت فرمایید و از آدرس کیف پول معتبر بیت کوین استفاده کنید. مسيولیت و عواقب آدرس کیف پول نامعتبر به عهده خریدار میباشد .',
        "feeTitle":'کارمزد انتقال در شبکه ',
        "btn":'ادامه',
        "minWithDrawal":'حداقل میزان برداشت از',
        "maxWithDrawal":'حداکثر میزان برداشت از',
        errors:{
            "emptyAmount":'مقدار را وارد کنید',
            "emptyWallet":'آدرس کیف پول را وارد کنید',
            "emptyNet":'شبکه ارسال را انتخاب کنید',
            "lowBalance":'موجودی کافی نیست',
            "continue":'ادامه',
        }
    },
    walletEmailModal:{
        "title":'شناسایی دو عاملی',
        "withDrawalTitle":'برداشت',
        "body1":'برای فعال سازی شناسایی دو عاملی، کد شش رقمی ارسال شده به',
        "body2":'را وارد کنید',
        "btn":'ثبت و فعال سازی',
        "withDrawalBtn":'ثبت و برداشت',
        "seconds":'ثانیه',
        "sendCode":'ارسال مجدد کد',
    },
    walletAdrresModal:{
        "title":'واریز به کیف پول  ',
        "body1":'آدرس کیف پول شما در زیر قابل مشاهده است . برای واریز',
        "body2":' به این کیف، می توانید از این آدرس استفاده کنید',
        "channel1":'شبکه',
        "channel2":'مورد نظر خود را انتخاب کنید',
        "warning1":'این کیف پول فقط برای واریز به ',
        "warning2":' میباشد. واریز هر ارز دیگری به این آدرس موجب از دست رفتن دارایی شما میشود',
        "btn":'کپی آدرس',
    },
    history:{
        "deposite":'واریز',
        "withDrawal":'برداشت',
        "coin":'ارز',
        "tradesTitle":'معاملات حرفه ای',
        "exchangesTitle":'صرافی',
        "despintsTitle":'واریز و برداشت رمز ارزی',
        "tomandespintsTitle":'واریز و برداشت ریالی',
        "coindespintsTitle":'واریز و برداشت رمز ارزی',
        "loginsTitle":'ورود ها',
        "allOprations":'تمام عملیات',
        "buy":'خرید',
        "sell":'فروش',
        "limit":'حد',
        "market":'بازار',
        "allCoins":'تمام بازار',
        "showBtn":'مشاهده',
        "oprations":'تمام عملیات',
        "markets":'تمام بازار',
    },
    tradesTable:{
        "market":'بازار',
        "opration":'عملیات',
        "price":'قیمت',
        "amount":'مقدار',
        "total":'مجموع',
        "fee":'کارمزد',
        "done":'انجام شده',
        "time":'زمان',
        "priceType":'تومان'
    },
    exchangeTable:{
        "coin":'ارز',
        "opration":'عملیات',
        "price":'قیمت',
        "amount":'مقدار',
        "total":'مجموع',
        "channel":'	شبکه ارسال',
        "situation":'وضعیت',
        "transactionId":'شناسه تراکنش',
        "time":'زمان',
    },
    despintTable:{
        "walletAddres":'آدرس کیف پول',
        "deposite":'واریز',
        "withDrawal":'برداشت',
        "coin":'ارز',
        "orderType":'نوع سفارش',
        "followId":'شناسه پیگیری',
        "opration":'عملیات',
        "value":'ارزش',
        "amount":'مقدار',
        "fee":'کارمزد',
        "channel":'شبکه ارسال',
        "situation":'وضعیت',
        "transactionId":'شناسه تراکنش',
        "time":'زمان',
    },
    loginTable:{
        "enter":'ورود',
        "success":'موفق',
        "failed":'نا موفق',
        "time":'زمان',
        "ip":'آی پی',
        "platform":'پلتفورم',
        "device":'دستگاه',
        "browser":'مرورگر',
        "situation":'وضعیت',
    },
    settings:{
        "title":'تایید هویت دو مرحله ای',
        "body":'برای امنیت بیشتر فعال سازی یکی از روش های دو عاملی ضروری میباشد',
        "changePassTitle":'تغییر رمز عبور',
        "btn":'ویرایش رمز',
        "email":'ایمیل',
        "sms":'شماره موبایل'
    },
    googleModal:{
        "title":'شناسایی دو عاملی',
        "body":'برای فعالسازی شناسایی دو عاملی، ابتدا برنامه Google Authenticator را دانلودنموده و سپس کد QR زیر را توسط آن اسکن کنید',
        "explanation":'پس از اسکن بارکد، مورد مربوط به بامبو در برنامه شما ایجاد خواهد شد. برای تایید صحت فرآیند، کدی که در اپ خود مشاهده میکنیدا در کادر زیر وادر کنید',
        "btn":'ثبت و فعال سازی',
        "copy":'کپی شد'
    },
    changePasswordTitle:{
        "title":'تغییر رمز عبور',
        "body":'برای تغییر رمز عبور، ابتدا رمز عبور فعلی خود را وارد کنید',
        "oldPassword":'رمز عبور فعلی',
        "newPassword":'رمز عبور جدید',
        "repeatPassword":'تکرار رمز عبور جدید',
        "btn":'ثبت',
    },
    notifications:{
        "title":'اعلانات',
    },
    inviteFriends:{
        "title":'دعوت دوستان',
        "body":'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.',
        "friendsNumber":'تعداد دوستان من',
        "person":'نفر',
        "totalBenefits":'مجموع سود من',
        "priceType":'تومان',
        "inviteLink":'لینک دعوت :',
        "inviteCode":'کد دعوت :',
        "copy":'متن کپی شد',
    },
    tickets:{
        "answer":'پاسخ',
        "title":'تیکت',
        "btn":'ارسال تیکت',
        "back":'بازگشت',
        "date":'تاریخ',
        "ticketTitle":'عنوان تیکت',
        "ticketSubject":'موضوع',
        "situation":'وضعیت',
        "seeDetails":'مشاهده جزئیات',
        "open":'باز',
        "isPending":'در حال بررسی	',
        "closed":'بسته'
    },
    ticketModal:{
        "sub1":'خرید',
        "sub2":'فروش',
        "sub3":'تراکنش ها',
        "sub4":'احراز هویت',
        "sub5":'سایر موارد',
        "answerTicket":'پاسخ تیکت',
        "answerBtn":'ثبت پاسخ',
        "enterAnswer":'پاسخ دهید',
        "title":'افزودن تیکت',
        "btn":'ارسال تیکت',
        "titleInput":'موضوع مورد نظر را وارد کنید',
        "subject":'عنوان مورد نظر را وارد کنید',
        "explanation":'شرح کاملی از موضوع را وارد کنید',
        "emptyText":'متن را وارد کنید',
        "emptyTitle":'موضوع را وارد کنید',
    },
    exitModal:{
        "title":'خروج از حساب کاربری',
        "body":'آیا میخواهید از حساب کاربری خود خوارج شوید؟',
        "yes":'بله',
        "no":'خیر'
    },
    advanceTrade:{
        defth:{
            "total":'مجموع',
            "amount":'مقدار',
            "price":'قیمت'
        },
        marketNumbers:{
            "toman":'تومان',
            "tether":'تتر',
            "price":'قیمت',
            "changes":'تغییرات',
            "coin":'بازار ',
            "time":'زمان',
            "amount":'مقدار',
        },
        header:{
            "volume":'حجم',
            "min":'کمترین',
            "max":'بیشترین',
            "changes":'تغییرات',
            
        },
        trade:{
            "limit":'حد',
            "market":'بازار',
            "balance":'موجودی',
            "fee":'کارمزد',
            "raiseBalance":'افزایش موجودی',
            "price":'قیمت',
            "amount":'مقدار',
            "expiredate":'تاریخ انقضا',
            "optional":'(اختیاری)',
            "total":'مجموع',
            "presentFee":'میزان کارمزد فعلی',
            "buy":'خرید',
            "sell":'فروش',
            "activeOrders":'سفارشات فعال',
            "orderHistory":'تاریخچه سفارشات',
            "dealType":'نوع',
            "done":'انجام شده',
            "opration":'عملیات',
            "cancle":'لغو سفارش',
            "time":'زمان ثبت',
            "completeTheAccount":'تکمیل حساب کاربری',
            "notAuthorized":'حساب تایید نشده',
            "min":'کمترین مقدار :',
            "max":'بیشترین مقدار :',
        },
        errors:{
            "emptyAmount":'مقدار را وارد کنید',
            "emptyPrice":'قیمت را وارد کنید',
            "lowBalance":'موجودی کافی نیست',
            "lowerThanMin":'کمتر از مقدار مجاز',
            "higherThanMax":'بیشتر از مقدار مجاز',
            "notAllowed":'مقدار مجاز نیست',
            "emptyBuySaleQueue":'صف خرید خالی میباشد',
            "emptySellSaleQueue":'صف فروش خالی میباشد',
        },
        activeOrders:{
            "buy":'خرید',
            "sell":'فروش',
            "modalTitle":'لغو سفارش',
            "modalBody":'از لغو سفارش خود مطمئن هستید ؟',
            "modalBtn":'لغو سفارش'
        }
    },
    exchange:{
        "buy":'خرید',
        "sell":'فروش',
        "priceType":'تومان',
        "raiseBalance":'افزایش موجودی',
        "balance":'موجودی',
        "priceIn":'قیمت به',
        "price":'قیمت',
        "amount":'مقدار',
        "dealVolume":'حجم قابل معامله',
        "min":'حداقل',
        "max":'حداکثر',
        "toMainWallet":'انتقال به کیف پول بلاک کارنسی',
        "toOtherWallet":'انتقال به کیف پول دیگر',
        "walletAddres":'آدرس کیف پول',
        "warning":'در وارد کردن آدرس کیف پول خود دقت فرمایید و از آدرس کیف پول معتبر بیت کوین استفاده کنید. مسئولیت و عواقب آدرس کیف پول نا معتبر به عهده خریدار میباشد',
        "total":'مجموع',
        },
        shopModal:{
            "title":'ثبت سفارش',
            "opration":'عملیات',
            "amount":'مقدار',
            "unitPrice":'قیمت واحد',
            "total":'مجموع',
            "expireTime":'تاریخ انقضا',
            "submit":'ثبت',
        },
        finoTech:{
            "title":'شناسایی دو عاملی فینوتک',
            "body":'کد ۶ رقمی ارسال شده به شماره خود را وارد نمایید',
            "submit":'ثبت',
            "seconds":'ثانیه',
            "nationalCode":'کد ملی',
            "phoneNumber":'شماره تلفن همراه',
            "code":'کد',
            "reSend":'ارسال مجدد کد',
        },
        channel:{
            "choose":'انتخاب شبکه'
        }
}