<template>
    <div class="faq">
        <div class="faq-container">
        <FAQ />
        </div>
        <div class="container">
                              <div class="question-box-container" > 
                    <div class="question-box" v-for="(item,index) in messages" :key="index">
                        <div @click="toggle(index)" class="question">
                            <p>{{item.question}}</p>
                            <img :class="{'active':showmessage[index]}" src="../../assets/FAQ/Vector.svg" alt="">
                        </div>
                            <transition name="view" >
                            <div class="answer" v-if="showmessage[index]">
                                <!-- <img src="../../assets/icons/video.png"> -->
                                <p>{{item.answer}}</p>
                            </div>
                            </transition>
                    </div>
                  </div>
        </div>
        <!-- <Swingbtn /> -->
    </div>
</template>

<script>
import FAQ from '../homepage/FAQ.vue'
// import Swingbtn from '../public/Swingbtn.vue'
    export default {
        name : 'Faq',
  components: { FAQ,
    // Swingbtn,
   },
           data () {
            return {
                questions : [
                    {
                        question : ' تحوه ثبت نام در بامبو به چه صورت است؟ ',
                        answer : 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.',
                        // media : require(''),
                        active : false,
                    },
                    {

                        question : 'تحوه ثبت نام در بامبو به چه صورت است؟ ',
                        answer : 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.',
                        // media : require(''),
                        active : false,
                    },
                    {
                        question : 'تحوه ثبت نام در بامبو به چه صورت است؟ ',
                        answer : 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.',
                        // media : require(''),
                        active : false,
                    },
                    {
                        question : 'تحوه ثبت نام در بامبو به چه صورت است؟ ',
                        answer : 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.',
                        // media : require(''),
                        active : false,
                    },
                ],
                messages:[],
                read: true,
                showmessage: [],
                messageheight: [],
            }
        },
                methods : {
            handleToggle (index) {
                    this.questions[index].active= !this.questions[index].active
            },
            toggle(e) {
                // this.messageheight[e] = document.getElementsByClassName('message-description')[e].firstChild.clientHeight + 20
                this.showmessage[e] = !this.showmessage[e];
                console.log(this.showmessage[e]);
                this.$forceUpdate()
            },
            async getFaq() {
                this.loading = true
                this.messages = await this.$axios.get('/faq?language='+(this.state.lan == 'Fa' ? 'PERSIAN':'ENGLISH'))
                for (let i = 0; i < this.messages.length; i++) {
                    this.showmessage[i] = false
                }
                this.loading = false

            }
        },
        mounted (){
            this.getFaq();
        }

    }
</script>

<style lang="scss" scoped>
.view-leave-active {
    transition: height 1s ease-in-out, transform 1s ease;
}

.view-enter-active {
    transition: height 1s ease-in-out, transform 1s ease;
}

.view-enter, .view-leave-to {
    height: 0;
}

.view-enter-to, .view-leave {
    height: 70px;
}
.faq-container{
    padding-top: 100px;
}
.answer{
    color: var(--fade-color);
    overflow: hidden;
    font-size: clamp(12px,1vw,10px);
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    img{
        // max-height: 233px;
        max-width: 478px;
    }
}
.question{
    color: var(--secondary-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .active{
        transform: rotate(180deg);
    }
    img{
        cursor: pointer;
        // width: 12px;
        // height: 7px;
        align-self: center;
    }

}
.question-box{
    cursor: pointer;
    transition: 2s;
    background: var(--primary-color);
    display: flex;
    flex-direction: column;
    // max-width: 510px;
    padding: 16px;
    border-radius: 4px;
    width: 100%;
}
.question-box-container{
    padding: 0 40px;
    width: 100%; 
    padding-bottom:130px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    }
</style>