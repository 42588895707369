<template>
    <div class="footer">
        <div class="conteiner">
            <div class="footer-container">
                <div class="footer-content">
                    <div class="flex social">
                        <div class="flex">
                            <h5>  {{state.lang.navbar.homeBtn}} </h5>
                            <p>{{state.lang.footer.explanation}}</p>
                        </div>
                        <div class="social-icons">
                            <!-- <a :href="info.facebook.includes('http')? info.facebook: `https://facebook.com/${info.facebook}`"
                           target="_blank"><img src="../../assets/footer/social/iconoir_facebook-squared.svg" alt="facebook"></a> -->
                        <a :href="info.telegram.includes('http')? info.telegram: `https://telegram.org/${info.telegram}`"
                           target="_blank"><img src="../../assets/footer/social/ph_telegram-logo-thin.svg" alt="Telegram"></a>
                        <a :href="info.instagram.includes('http')? info.instagram: `https://instagram.com/${info.instagram}`"
                           target="_blank"><img src="../../assets/footer/social/ph_instagram-logo-thin.svg" alt="Instagram"></a>
                        </div>
                    </div>
                    <div class="flex fast-access">
                        <div>
                             <h5>{{state.lang.footer.quickAccess}}</h5>
                        </div>
                        <div class="flex">
                            <a @click.prevent="reDirect(item.addres)" v-for="(item,index) in links" :key="index" :href="item.addres">{{item.title}}</a>
                        </div>
                    </div>
                    <div class="flex about">
                       <div>
                            <h5>{{state.lang.footer.contactUs}}</h5>
                       </div>
                            <div class="flex">
                                 <div class="about-item">
                                <img :src=addres.img alt="">
                                <p>{{info.address}}</p>
                                 </div>
                                 <div class="about-item">
                                <img :src=phone.img alt="">
                                <p>{{info.number}}</p>
                                 </div>
                                 <div class="about-item">
                                <img :src=email.img alt="">
                                <p>{{info.email}}</p>
                                 </div>
                            </div>
                    </div>
                </div>
                <div class="label">
                    <p>{{state.lang.footer.vista}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'Footer',
        data () {
            return {
                home: true,
                logedin: false,
                info:{
                        address: '',
                    appAndroid: '',
                    appIOS:'',
                    email: '',
                    instagram: '',
                    linkedin: '',
                    number: '',
                    telegram: '',
                    twitter: '',
                    privacyPolicy:'',
                    },
                links : [
                    {
                        title : this.state.lang.footer.contactUs,
                        addres : 'aboutus',
                    },
                    {
                        title : this.state.lang.navbar.support,
                        addres : 'Contactus',
                    },
                    {
                        title : this.state.lang.navbar.blog,
                        addres : '',
                    },
                    {
                        title : this.state.lang.footer.termsAndConditions,
                        addres : 'Privacypolicy',
                    },
                    { 
                        title : this.state.lang.footer.faq,
                        addres : 'Faq',
                    },
                ],
                    addres:{
                        txt : '  خیابان مطهری، نرسیده به میرزای شیرازی، پلاک ۲۹۹ واحد۳ ',
                        img : require('../../assets/footer/about/noun_location pin_4382115.svg')
                    },
                    phone:{
                        txt : '۰۹۱۹ ۵۶۲ ۱۲۶۶',
                        img : require('../../assets/footer/about/clarity_phone-handset-line.svg')
                    },
                    email:{
                        txt : 'vista@vista.com',
                        img : require('../../assets/footer/about/carbon_email.svg')
                    }
            }
        },
        methods : {
            reDirect(addres){
                this.$router.push({ name : addres});
                console.log(addres);
            },
            async getInfo() {
                const res = await this.$axios.get('/exchange-info?language='+(this.state.lan == 'Fa' ? 'PERSIAN':'ENGLISH'))
                // this.info = res.baseDTO || {
                //     address: '',
                //     appAndroid: '',
                //     appIOS:'',
                //     email: '',
                //     instagram: '',
                //     linkedin: '',
                //     number: '',
                //     telegram: '',
                //     twitter: '',
                //     privacyPolicy:'',
                // }
                this.info.address = res.baseDTO.address
                this.info.email = res.baseDTO.email
                this.info.number = res.baseDTO.number
                // this.state.appAndroid = this.info.appAndroid
                // this.state.appIOS = this.info.appIOS
                // .catch(err=>{
                //     console.log(err);
                // })
            },
        },
        mounted (){
            this.getInfo();
        }
    }
</script>

<style lang="scss" scoped>
.label{
   align-self: end;
   width: 85%;
   p{
       font-weight: 700;
       font-size: clamp(10px,1vw,12px);
   }
}
a{
    text-align: start;
    color: rgba(4, 24, 54, 1);
}
a:hover{
    transform: none;
}
    h5{
        font-weight: 400;
        font-size: clamp(26px,1vw,36px);
    }
    .fast-access{
        justify-content: start;
    }
    .about-item{
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        p{
            text-align: start;
            align-self: center;
        }
    }
    .flex{
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }
    .footer{
        min-height: 310px;        
        background: var(--primary-color);
    }
    .footer-container{
        align-items: center;
        box-sizing: border-box;
        padding: 32px 0;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        // justify-content: space-evenly;
    }
    .about{
        width: 30%;
    }
    .footer-content{
        .social{
            p{
                        font-weight: 700;
        font-size: clamp(11px,1vw,14px);
            }
            .social-icons{
                display: flex;
                column-gap: 10px;
            }
            width: 30%;
            row-gap: 20px;
        }
        width: 70%;
        padding: 20px 0px;
        border-bottom: 0.2px solid rgba(0, 119, 204, 0.8);
        // opacity: 0.8;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    @media only screen and (max-width:1000px){
        .about{
            width: 100%;
        }
        .social{
            
            width: 100% !important;
        }
        .footer-content{
            row-gap: 40px;
        }
    }
</style>