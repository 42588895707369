<template>
    <div>
        <!-- <Swingbtn /> -->
        <div class="about">
            <div class="container about-container">
                <h3> {{state.lang.navbar.homeBtn}} </h3>
                <p>{{state.lang.contactUs.explanation}}</p>
                <h5>{{state.lang.contactUs.social}}</h5>
                <div class="icon">
                       <!-- <a :href="info.facebook.includes('http')? info.facebook: `https://facebook.com/${info.facebook}`"
                           target="_blank"><img src="../../assets/contactus/iconoir_facebook-squared.svg" alt="facebook"></a> -->
                        <a :href="info.telegram.includes('http')? info.telegram: `https://telegram.org/${info.telegram}`"
                           target="_blank"><img src="../../assets/contactus/ph_telegram-logo-thin.svg" alt="Telegram"></a>
                        <a :href="info.instagram.includes('http')? info.instagram: `https://instagram.com/${info.instagram}`"
                           target="_blank"><img src="../../assets/contactus/ph_instagram-logo-thin.svg" alt="Instagram"></a>
                </div>
            </div>
        </div>
        <div class="container contact">
            <div class="contact-container">
                    <div class="about-item">
                                <img :src=addres.img alt="">
                                <p>{{info.address}}</p>
                                 </div>
                                 <div class="about-item">
                                <img :src=phone.img alt="">
                                <p>{{info.number}}</p>
                                 </div>
                                 <div class="about-item">
                                <img :src=email.img alt="">
                                <p>{{info.email}}</p>
                                 </div>
            </div>
            <!-- <img src="../../assets/contactus/MaskGroup.svg" alt=""> -->
            <l-map style="height: 300px" class="map" :zoom="zoom" :center="center">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-marker :lat-lng="markerLatLng"></l-marker>
            </l-map>
        </div>
    </div>
</template>

<script>
// import Swingbtn from '../public/Swingbtn.vue'
    export default {
        name : 'Contactus',
        // components :{Swingbtn,},
        data () {
            return {
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                attribution:
                    '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                zoom: 15,
                center: [35.715993,51.406934],
                markerLatLng: [35.715993,51.406934],
                 home: true,
                logedin: false,
                info:
                    {
                        address: "",
                        // facebook:"",
                        appAndroid: "",
                        appIOS: "",
                        email: "",
                        instagram: "",
                        linkedin: "",
                        number: "",
                        telegram: "",
                        twitter: ""
                    },
                    addres:{
                        txt : '  خیابان مطهری، نرسیده به میرزای شیرازی، پلاک ۲۹۹ واحد۳ ',
                        img : require('../../assets/footer/about/noun_location pin_4382115.svg')
                    },
                    phone:{
                        txt : '۰۹۱۹ ۵۶۲ ۱۲۶۶',
                        img : require('../../assets/footer/about/clarity_phone-handset-line.svg')
                    },
                    email:{
                        txt : 'vista@vista.com',
                        img : require('../../assets/footer/about/carbon_email.svg')
                    },
            }
        },
        methods:{
            async getInfo() {
                const res = await this.$axios.get('/exchange-info')
                this.info = res.baseDTO || {
                    address: '',
                    appAndroid: '',
                    // facebook:'',
                    appIOS: '',
                    email: '',
                    instagram: '',
                    linkedin: '',
                    number: '',
                    telegram: '',
                    twitter: ''
                }
                // this.state.appAndroid = this.info.appAndroid
                // this.state.appIOS = this.info.appIOS
                // .catch(err=>{
                //     console.log(err);
                // })
            },
        },
        mounted(){
            this.getInfo();
        }
    }
</script>

<style lang="scss" scoped>
.map{
    width: 50%;
}
.about-item{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    img{
        max-width: 28px;
    }
}
    .contact-item{
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        img{
            max-width: 32px !important;
            max-height: 32px !important;
        }
        p{
            text-align: start;
            align-self: center;
        }
    }
.contact{
    column-gap: 10px;
    padding:60px 120px;
    .contact-container{
        align-self: flex-start;
            display: flex;
    flex-direction: column;
    row-gap: 20px;
    }
    img{
        width: 100%;
        max-height: 403px;
        // max-width: 816px;
    }
}
.about{
    .about-container{
        h3{
            font-size: clamp(36px,1vw,48px);
        }
        p{
            font-weight: 400;
            font-size: clamp(12px,1vw,18px);

        }
        h5{
            font-weight: 700;
            font-size: clamp(18px,1vw,24px);
        }
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        row-gap: 20px;
        padding:80px 120px;
    }
    .icon{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        img{
            width: 42px;
            height: 42px;
        }

    }
    background: var(--secondary-color);
    color: white;
}
@media only screen and(max-width:1200px) {

    .contact{
        flex-direction: column;
        row-gap: 20px;
        .contact-container{
            align-self: center;
        }
    }
    .contact,.about-container{
        padding:80px 20px !important;
    }
    .map{
        width: 100%;
    }
    
}
</style>