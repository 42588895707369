<template>
    <div class="otpemail">
        <Simplemodal v-if="finish" :pic="require('../../assets/icons/successpass.svg')" :text="'درخواست شما با موفقیت انجام شد'" />
        <form @submit.prevent="submit" action="">
                <h1> {{state.lang.forgotPassNew.title}} </h1>
                <p>{{state.lang.forgotPassNew.body}} </p>
                <label :class="[state.lan=='Fa' ? 'Vuepass-fa' : 'Vuepass-en' ]" @click="blanckspace.password = true" class="passlabel" for="">
                    <!-- <input @focus="blanckspace.password = true" :type="passtype" v-model="data.password"  class="password" name="password"> -->
                    <!-- <input @focus="blanckspace.password = true" v-model="data.password"  v-else class="password" name="password" type="password"> -->
                    <VuePassword
                            v-model="data.password"
                            id="password1"
                            @input="updateStrength(data.password)"
                            :strength="strength"
                            :type="passtype"
                        />
                    <span class="pass-span password" :class="[{'focus':(blanckspace.password)},state.lan=='En'?'placeh-en':'placeh-fa']">{{state.lang.forgotPassNew.passWord}} </span>
                    <!-- <img v-if="passtype=='password'" class="password" @click="passtype='text'"  src="../../assets/icons/noun_Eye_4382116.svg" alt=""> -->
                    <!-- <img v-if="passtype=='text'" @click="passtype='password'"  src="../../assets/icons/seepassword.svg" alt=""> -->
                </label>
                 <div v-if="passwordError" class="note">
                        <img src="../../assets/icons/error.svg" alt="">
                        <p>{{passwordError}}</p>
                </div>
                <label class="passlabel" for="">
                    <input @focus="blanckspace.passwordrepeat = true" v-model="data.passwordrepeat" :type="passrepeattype" class="password" name="password">
                    <span class="password" :class="[{'focus':(blanckspace.passwordrepeat)},state.lan=='En'?'placeholder-en':'placeholder-fa']"> {{state.lang.forgotPassNew.passwordRepeat}}  </span>
                    <img :class="[state.lan=='En'?'img-en':'img-fa']" v-if="passrepeattype=='password'" class="password" @click="passrepeattype='text'"  src="../../assets/icons/noun_Eye_4382116.svg" alt="">
                    <img :class="[state.lan=='En'?'img-en':'img-fa']" v-if="passrepeattype=='text'" @click="passrepeattype='password'"  src="../../assets/icons/seepassword.svg" alt="">
                </label>
                 <div v-if="passwordRepeatError" class="note">
                        <img src="../../assets/icons/error.svg" alt="">
                        <p>{{passwordRepeatError}}</p>
                </div>
                <button :disabled="!data.password || !data.passwordrepeat" class="submit">{{state.lang.forgotPassNew.btn}} </button>
        </form>
    </div>
</template>

<script>
import Simplemodal from '../Tools/Simplemodal.vue';
import VuePassword from 'vue-password';
    export default {
        name : 'Forgotpass',
        components : {
            Simplemodal,
            VuePassword
        },
        data () {
            return{
                strength:0,
                passtype:'password',
                passrepeattype:'password',
                close : true ,
                finish:false,
                unfocus : true,
                blanckspace : {
                    email : false,
                    password : false,
                    passwordrepeat:false,
                    invitecode : false,
                    recaptcha : false
                },
                data:{
                    password:'',
                    passwordrepeat:'',
                },
                passwordError:'',
                passwordRepeatError:'',
            }
        },
        methods: {
            toggleFocus () {
                this.blanckspace.email=true ;
                this.unfocus=true;
            },
            toggleVisible () {
                this.visibality=!this.visibality
            },
            checkPass() {
                if (!this.data.password) {
                    this.passwordError = this.state.lang.formsError.blanckEmail;
                } else {
                    if (this.data.password.length < 8) {
                        this.passwordError = this.state.lang.formsError.minPass;
                    } else if (!this.$validPass(this.data.password)) {
                        this.passwordError = this.state.lang.formsError.wrongCombination;
                    } else if(this.data.password!==this.data.passwordrepeat){
                        this.passwordRepeatError = this.state.lang.formsError.wrongRepeat;
                        return false;
                    } else {
                        this.passwordError = ''
                    }
                }
                if (!this.passwordError.length) {
                    return true;
                }
            },
            async submit() {
                if (this.checkPass(this.data.password) && !this.disabled) {
                    this.state.loading = true
                    const headers = {"Authorization": `Bearer ${this.state.otptoken}`,}
                    console.log('hi');
                    let [res,] = await this.$http.post('/users/v2/reset-password',this.data,{headers},this.data,{headers})
                        console.log(this.state.otptoken);
                    if (res) {
                        this.finish=true;
                         this.$emit('closeModaleEmit' , this.close)
                        this.$error(this.state.lang.formsError.successChange, '', 'success')
                        await this.$router.push('/Login')
                    }
                }
            },
            updateStrength(pass){
                if (pass.match(/[a-z]+/) || pass.match(/[0-9]+/) || pass.match(/[A-Z]+/)){
                    this.strength=1;
                    console.log(this.passtype);
                }
                if ((pass.match(/[0-9]+/) && pass.match(/[a-z]+/)) || (pass.match(/[0-9]+/) && pass.match(/[A-Z]+/)) || (pass.match(/[A-Z]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[A-Z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[0-9]+/))){
                    this.strength=2;
                }
                if ((pass.match(/[0-9]+/) && pass.match(/[A-Z]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[A-Z]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[0-9]+/) && pass.match(/[a-z]+/)) || (pass.match(/[$@#&!]+/) && pass.match(/[0-9]+/) && pass.match(/[A-Z]+/))){
                    this.strength=3;
                }
                if (pass.match(/[$@#&!]+/) && pass.match(/[0-9]+/) && pass.match(/[A-Z]+/) && pass.match(/[a-z]+/)){
                    this.strength=4

                    }
                    if(pass==''){
                        this.strength=0
                    }
            }
        },
        mounted(){
           console.log(this.state.otptoken); 
        }
    }
</script>

<style lang="scss" scoped>
.placeh-en{
    right: unset !important;
    left: 16px !important;
    bottom: 15px;
}
.placeh-fa{
    left: unset !important;
    right: 16px !important;
    bottom: 17px;
}
.placeholder-en{
    right: unset !important;
    left: 16px !important;
    bottom: 5px;
}
.placeholder-fa{
    left: unset !important;
    right: 16px !important;
    bottom: 5px;
}
.focus{
               transform: translateY(-20px) scale(0.8,0.8);
        background: white;
        z-index: 1;   
    }
    label{
    position: relative;
    span {
    transition: 1s;
        z-index: -1;
        color: var(--secondary-color);
        position: absolute;
        bottom: 10px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
input{
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
}
.otpemail{
    // max-width: 336px;
    width: 35%;
    box-sizing: border-box;
    
}
.passlabel{
    img{
        position: absolute;
        bottom: 10px;
        left: 15px;
        cursor: pointer;
        max-height: 16px;
        max-width: 16px;
    }
}
.submit{
    width: 100%;
}
form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 250px;
}
  @media only screen and (max-width:900px){


      .otpemail{
          width: 70%;
        padding: 0 20px;
      }
}
  @media only screen and (max-width:550px){

      .otpemail{
          width: 100%;
        padding: 0 20px;
      }
}
</style>