<template>
<div>
    <div>
        <div class="aboutus">
            <div class="container">
                <div class="page-container">
                    <img src="../../assets/aboutus/undraw_pay_online_b-1-hk.svg" alt="">
                    <div class="text-container">
                        <h1>{{state.lang.aboutUs.title}}</h1>
                        <p>{{state.lang.aboutUs.explanation}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Cards />
    <div class="statistic">
      <div class="container stats-container">
            <div class="stats" >
                <h5>{{$toLocal(userscount)}}</h5>
                <h4>{{state.lang.statistic.dealValue}}</h4>
            </div>
            <div class="stats" >
                <h5>{{$toLocal(totaltrad)}}</h5>
                <h4>{{state.lang.statistic.doneDeal}}</h4>
            </div>
            <div class="stats" >
                <h5>{{$toLocal(tradprice.toFixed(0))}}</h5>
                <h4>{{state.lang.statistic.users}}</h4>
            </div>
        </div>
    </div>
    <div class="container p-container">
        <p>{{state.lang.aboutUs.conclusion}}</p>
    </div>
    <!-- <Swingbtn /> -->
</div>
</template>

<script>
import Cards from '../homepage/Cards.vue'
// import Swingbtn from '../public/Swingbtn.vue'
    export default {
        name : 'Aboutus',
  components: {
    Cards,
    // Swingbtn,
        },
                data () {
            return {
                userscount: 0,
                tradprice: 0,
                totaltrad: 0,
            }
        },
        methods:{
            async getInfo() {
                            const res = await this.$axios('trades/market-info-home')
                            this.userscount = res.baseDTO.userCount
                            this.tradprice = res.baseDTO.tradesValue
                            this.totaltrad = res.baseDTO.tradesAmount
                        },
        },
        mounted (){
            this.getInfo();
        },

        
    }
</script>

<style lang="scss" scoped>
p{
    font-weight: 700;
    font-size: clamp(8px,1vw,18px);
}
.p-container{
padding: 120px 50px;
font-size: clamp(14px,1vw,18px);
font-weight: 400;
}
.statistic{
        min-height: 237px;
        background:var(--primary-color) ;

    .stats-container{
        box-sizing: border-box;
        padding: 64px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        row-gap: 30px;
        flex-wrap: wrap;
       .stats{
           text-align: center;
           display: flex;
           flex-direction: column;
           justify-content: space-between;
           height: 100px;
           h4{
               color: var(--banner-primary) ;
               font-weight:700 ;
               font-size:clamp(18px,1vw,24px) ;
           }
           h5{
            //    color: var(--primary-color);
               font-weight:700 ;
               font-size:clamp(27px,1vw,36px) ;
           }
       } 
    }
}
.page-container{
    img{
        width: 50%;
    }
    direction: ltr;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding:80px 0 ;
    justify-content: space-between;
}
.text-container{
    display: flex;
    flex-direction: column ;
    row-gap: 20px;
    justify-content: center;
    width: 45%;
}
@media only screen and(max-width:800px){
    .page-container{
        flex-direction: column;
        align-items: center;
    }
    .text-container{
        width: 90%;
    }
}
</style>