<template>
    <div class="flex">
        <Dashboardnavbar @close="exitmodal=true" v-if="username" />
        <Exitmodal v-if="exitmodal" @closeChargeModaleEmit="exitmodal=false" />
        <Navbar v-if="!username" />
        <Banner />
        <Markettable />
        <Cards />
        <Steps />
        <Statistics />
        <FAQ />
        <!-- <Swingbtn /> -->
        <Footer />
    </div>
</template>


<script>
import Exitmodal from '../Profile/Exitmodal.vue';
import Banner from './Banner.vue';
import Markettable from './Markettable.vue';
import Cards from './Cards.vue';
import Steps from './Steps.vue';
import Statistics from './Statistics.vue';
import FAQ from './FAQ.vue';
// import Swingbtn from '../public/Swingbtn.vue';
import Navbar from '../public/Navbar.vue';
import Footer from '../public/Footer.vue';
import Dashboardnavbar from '../Profile/Dashboardnavbar.vue';
    export default {
        name : 'Homepage',
        components : {
            Banner,
            Markettable,
            Cards,
            Steps,
            Statistics,
            FAQ,
            Exitmodal,
            // Swingbtn,
            Navbar,
            Footer,
            Dashboardnavbar

        },            
        data(){
            return{
                username:null,
                exitmodal:false,
            }
        },
        mounted(){
            this.username=this.state.userInfo.firstName+this.state.userInfo.lastName
            if(!this.username){
                this.username=this.$cookies.get("email");
            }
        }  
                      
    }
</script>

<style lang="scss" scoped>
    .flex{
        display: flex;
        flex-direction: column;
        row-gap: 50px;
    }

</style>