<template>
    <div class="market">
        <!-- <Swingbtn /> -->
        <div class="market-container">
            <div class="title">
                <h1>{{state.lang.navbar.market}}</h1>
                <div class="searchbar">
                    <label class="passlabel" for="">
                        <input @input="search" v-model="word" :placeholder="state.lang.market.search" name="password" type="text">
                        <img :class=" state.lan=='En' ? 'password-en':'password'" src="../../assets/icons/searchicon.svg" alt="">
                    </label>
                </div>
            </div>
            <div class="marke-container">
        <div class="market-background">
            <table :class="{'fa-table':state.lan=='Fa'}" class="market">
                    <tr class="market-header">
                        <td :class="{'btn-td' : state.lan=='Fa'}">
                        <div :class=" state.lan=='Fa' ? 'toggle-btns-fa' : 'toggle-btns-en'">
                            <div @click="priceToggle('TOMAN')" :class="{ 'active' :pricetype=='TOMAN' }" class="toggle-btn">{{state.lang.marketTable.toman}}</div>
                            <div @click="priceToggle('TETHER')" :class="{ 'active' :pricetype=='TETHER' }" class="toggle-btn">{{state.lang.marketTable.tether}}</div>
                        </div>
                        </td>
                        <td v-for="(item,index) in tableitems" :key="index" :class="item.class"  class="header-text">{{item.title}}</td>
                    </tr> 
                    <tbody :class="{'td-en':state.lan=='En'}" class="market-content">
                    <tr @click.prevent="$router.push('/Advancetrade/'+pricetype+'/'+$coin2Snake[coin.relatedCoin])" v-for="(coin,index) in filterPrices" :key="index" class="coin-information" >
                        <td>
                            <div :class="state.lan=='Fa'?'coins-fa':'coins-en'"> 
                                <div class="coin-container">    
                                    <img :class="{'en-order' : state.lan=='En'}" class="remove-two" :src="'/coins/'+$coin2Snake[coin.relatedCoin]+'.png'"  :alt="coin.relatedCoin">
                                    <span v-if="state.lan=='Fa'" class="coin-name">{{$coinLabel[coin.relatedCoin]}}</span>
                                    <span v-if="state.lan=='En'" class="en-coin-name coin-name">{{$coin2Snake[coin.relatedCoin]}}</span>
                                </div>
                            </div>
                        </td>
                        <td class="" :class="{'positive' : coin.last24HourChange > 0 , 'negative' : coin.last24HourChange < 0}">
                            <span v-if=" coin.last24HourChange < 0">{{$toLocal(coin.last24HourChange,2)}}%-</span>
                             <span v-if="coin.last24HourChange > 0">{{$toLocal(coin.last24HourChange,2)}}%+</span>
                             <span v-if="coin.last24HourChange == 0">{{$toLocal(coin.last24HourChange)}}</span>
                        </td>
                        <td  class="number-style remove-four">{{$toLocal(coin.lastPrice,$decimal[pricetype])}}</td>
                        <td class="number-style remove-three">0</td>
                        <td class="number-style remove-three">0</td>
                        <td class="remove">
                            <Trendchart :class="state.lan=='En' ? 'graph-en' : 'graph-fa'" :smooth="false" :trend="coin.lastWeekData"/>
                        </td>
                        <td class=" remove-two">
                            <div :class="state.lan=='En' ? 'coin-opration-en' : 'coin-opration'">
                            <a @click.prevent="$router.push('/Advancetrade/'+pricetype+'/'+$coin2Snake[coin.relatedCoin])" class="positive">{{state.lang.marketTable.buy}}</a>
                             <a @click.prevent="$router.push('/Advancetrade/'+pricetype+'/'+$coin2Snake[coin.relatedCoin])" class="negetive">{{state.lang.marketTable.sell}}</a>
                            </div>
                            </td>
                    </tr>                    
                    </tbody>
            </table>
        </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Swingbtn from '../public/Swingbtn.vue'
import Trendchart from '../homepage/Trendchart.vue'
    export default {
  components: {
        // Swingbtn,
        Trendchart,
         },
        name:'Market',
        data () {
            return{
                 pricetype:'TOMAN',
                TomanCoin: 'market_type=BITCOIN_TOMAN' +
                  '&market_type=BITCOIN_CASH_TOMAN' +
                  '&market_type=ETHEREUM_TOMAN' +
                  '&market_type=LITE_COIN_TOMAN' +
                  '&market_type=BINANCE_COIN_BSC_TOMAN' +
                  
                  '&market_type=STELLAR_TOMAN' +
                  '&market_type=TRON_TOMAN' +
                  '&market_type=CELO_TOMAN' +
                  '&market_type=RIPPLE_TOMAN' +
                  '&market_type=DOGE_COIN_TOMAN' +
                  '&market_type=MATIC_TOMAN' +
                
                  '&market_type=TETHER_TOMAN',



              TetherCoin: 'market_type=BITCOIN_TETHER' +
                  '&market_type=BITCOIN_CASH_TETHER' +
                  '&market_type=ETHEREUM_TETHER' +
                  '&market_type=LITE_COIN_TETHER' +
                  '&market_type=BINANCE_COIN_BSC_TETHER' +
                  '&market_type=MATIC_TETHER' +

                  '&market_type=STELLAR_TETHER' +
                  '&market_type=TRON_TETHER' +
                  '&market_type=RIPPLE_TETHER' +
                  '&market_type=DOGE_COIN_TETHER' +
                  '&market_type=CELO_TETHER', 
                tableitems : [
                    {
                        title : this.state.lang.marketTable.changes ,
                        class : '',
                    },
                    {
                        title : this.state.lang.marketTable.worldPrice,
                        class : 'remove-four',
                    },
                    {
                        title : this.state.lang.marketTable.buyPrice,
                        class : 'remove-three',
                    },
                    {
                        title : this.state.lang.marketTable.sellPrice,
                        class : 'remove-three',
                    },
                    {
                        title : this.state.lang.marketTable.graph,
                        class : 'remove',
                    },
                    {
                        title : this.state.lang.marketTable.opration,
                        class : 'remove-two opration',
                    },
                ],
                word:'',
                focus:true,
                coins:[],
            }
        },
        computed:{
            filterPrices() {
            let filterPrice = this.coins
            if (this.word) {
                // var persianName = this.$coins[this.destCoinSymbol].persianName
                filterPrice = this.coins.filter(a => this.$coin2Snake[a.relatedCoin].includes(this.word.toUpperCase()) || this.$coinLabel[a.relatedCoin].includes(this.word.toUpperCase()))
            }
            return filterPrice
            },
        },
        methods:{
            iconClick () {
                this.iconclick = !this.iconclick
            },
            priceToggle(val){
                this.pricetype=val;
                this.setData();
            },
            async setData() {
                    this.state.loading = true
                    const res = await this.$axios.get('/trades/market-info?' + (this.pricetype == 'TOMAN' ? this.TomanCoin : this.TetherCoin),{
                        params: {
                            weeklyDataIncluded: true,
                        }
                    })
                    this.coins = res.content
                    // console.log('week',this.coin);
            },
        },
        mounted(){
            this.setData() ;     
        }
    }
</script>

<style lang="scss" scoped>
.coin-information:hover{
    cursor: pointer;
    background: var(--primary-color);
}
.coins-fa{
    margin-right: 10px;
    display: flex;
    width: 100%;
    justify-content: start;
}
.coins-en{
    margin-left: 10px;
    display: flex;
    width: 100%;
    justify-content: end;
}
.coin-opration-en{
        width: 100% !important;
        justify-content: end !important;
        column-gap: 15px;
        display: flex;
    }
.graph-en{
        display: flex;
    justify-content: end;
}
.td-en{
    td{
        text-align: end;
        span{
                display: flex;
                align-items: center;
                width: 100%;
                text-align: start;
                justify-content: end;
        }
    }
}
.en-order{
    order: 1;
        margin-left: 10px;
}
.en-coin-name{
    width: unset !important;
}
.marke-container{
    position: relative;
}
// .market-background::before{
//     content: ""; 
//     position: absolute;
//     top: 300px;
//     right: 500px;
//     width: 61%;
//     height: 106%;
//     opacity: .3;
//     background: url('../../assets/background/Vector.svg');
//     background-repeat:no-repeat ;
//     z-index: -1000;
// }
.market-background{
    background: white;
}

.market-background::after{
        content: ""; 
    position: absolute;
    top: -250px;
    left: 130px;
    width: 61%;
    height: 106%;
    opacity: .6;
    background: url('../../assets/Banner/marketbackground.png');
    background-repeat:no-repeat;
    z-index: -1000;

}
.button-container{
    width: 400%;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
}
button{
    background: rgba(242, 246, 255, 1);
    color: var(--secondary-color);
    font-weight: 700;
    width: 20%;
    align-items: center;
    &:hover{
        width: 25%;
    }
}
    .header-text{
        color: var(--secondary-color);
    }
    .coin-name{
        color:var(--secondary-color);
    }
    .number-style{
        font-weight: 400;
        font-size: clamp(13px,1vw,10px);
        color:rgba(79, 79, 79, 1);
    }
    .coin-opration{
    color:  rgba(20, 59, 116, 1);
    display: flex;
    justify-content: space-between;
    width: 65%;
    height: 40px;
    }
    span{
        display: flex;
        align-items: center;
    }
    img{
        height: 37px;
    }
    .positive{
        color: rgba(51, 158, 0, 1);
        &:hover{
            cursor: pointer;
        }
    }
    .negetive{
        color: #FF005C;
        direction: ltr;
        text-align: end;
                &:hover{
            cursor: pointer;
        }
    }
    .coin-container{
        justify-content: center;
        display: flex;
        column-gap: 10px;


    }

    .marke-container{
        // overflow: hidden;
        display: flex;
        justify-content: center;
        padding-top: 32px;
        padding-bottom: 100px;
    }
    .market-background{
        width: 100%;
    }
    .market{
        width: 100%;
    }
    .market-content{
        width: 100%;
        flex-direction: column;
        direction: rtl;
        font-size: clamp(14px,1vw,11px);
        row-gap: 20px;
    }
    .market-header {
        box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
            font-size: clamp(14px,1vw,12px); 
        width: 100%;
        height: 69px;
    }
    table{
        td{
            max-width: 100px;
        }
            padding: 10px 10px;
        box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
        border-collapse: collapse;
    }
    .btn-td{
        display: flex;
        justify-content: center;
        min-height: 40px;
        align-items: flex-end;
        min-height: 52px;
        min-width: 100px;
        }
    .toggle-btns{
        background: rgba(242, 246, 255, 1);
        width: 80%;
        max-width: 125px;
        border-radius: 4px;
        overflow: hidden;
    display: flex;
    flex-direction: row;
    height: 40px;
    }
    .toggle-btns-en{
        margin-left: 10px;
        background: rgba(242, 246, 255, 1);
        width: 100%;
        max-width: 125px;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        margin-left: 10px;
        // flex-direction: row;
        height: 40px;
    }
    .toggle-btns-fa{
        margin-right: 10px;
        background: rgba(242, 246, 255, 1);
        width: 100%;
        max-width: 125px;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        height: 40px;
    }
    .toggle-btn{
        height: 100%;
        display: flex;
        align-items:center;
        justify-content: center;
        width: 50%;
        color: rgba(164, 198, 222, 1);
        &:hover{
            cursor: pointer;
        }

    }
    tbody td{
        padding-top: 10px;
    }
    .active{
        transition: 800ms;
        background: rgba(0, 119, 204, 1);
        color: rgba(242, 242, 242, 1);
        &:hover{
            cursor:default;
        }
    }
    @media only screen and (max-width: 1200px){

      .market-background::before{
       background: none;
       background-repeat:no-repeat ;
       position: unset;
        }
            .market-background::after{
       background: none;
       background-repeat:no-repeat ;
       position: unset;
        }
    }
    @media only screen and (max-width: 1200px){
        .coin-opration{
            // width: 100%;
            padding-left: 40px;
        }
    }
    @media only screen and (max-width: 930px){
        .button-container{
            width: 280%;
            button{
                width: 30%;
                &:hover{
                    width: 35%;
                }
            }
        }
    .remove{
            display: none;
        }
        .search-input{
            width: 40%;
        }

    }
    @media only screen and (max-width: 830px){
               .button-container{
            width: 180%;
            button{
                width: 35%;
                &:hover{
                    width: 40%;
                }
            }
        }
    .remove-two{
            display: none;
        }
    }
    @media only screen and (max-width: 650px){
               .button-container{
            width: 120%;
            button{
                width: 70%;
                &:hover{
                    width: 90%;
                }
            }
        }
    .remove-three{
            display: none;
        }
            .search-input{
            width: 60%;
        label{
            margin-bottom: 0;
        }
        }
    }
    @media only screen and (max-width: 430px){
                .search-input{
            width: 80%;
        }
    .remove-four{
            display: none;
        }
    }









.market-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 64px 120px;
    width: 100%;
    .title{
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: row;
        h1{
            width: 40%;
            text-align: start;
            align-self: flex-start;
            justify-self: start;
        }
        .searchbar{
            align-self: center;
            justify-self: start;
            width: 60%;
            label{
                position: relative;
                .password{
                    position: absolute;
                    top: -5px;
                    left: 16px;
                }
                .password-en{
                    position: absolute;
                    top: -5px;
                    right: 16px;
                }
                input{
                    box-sizing: border-box;
                    padding: 16px;
                    box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
                    border-radius: 4px;
                    width: 80%;
                    height: 64px;
                    &::placeholder{
                        color: var(--secondary-color);
                    font-weight: 700;
                    font-size: clamp(18px,2vw,24px);
                    }
                }
            }
        }
    }
}
@media only screen and(max-width:1000px){
    .market-container{
        .title{
            flex-direction: column;
            row-gap: 60px;
            h1{
                width: 100%;
                text-align: center;
            }
            .searchbar{
                width: 100%;
                label{
                    width: 100%;
                    input{
                        width: 100%;
                    }
                }
            }
        }
        padding: 64px 20px;
    }
}
</style>