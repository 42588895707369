<template>
    <div class="buysell">
        <div class="btn">
            <button @click="changeMarket(true)" :class="{'submit':marketclick==true}" >{{state.lang.advanceTrade.trade.limit}}</button>
            <button @click="changeMarket(false)" :class="{'submit':marketclick==false}">{{state.lang.advanceTrade.trade.market}}</button>
        </div>
        <div class="opration">
            <!-- sell CRY -->
            <SellCrypto :role="role" :cash="sellCredit" :wage="state.userInfo.fee" :marketclick="marketclick" />
            <!-- buy CRY -->
            <BuyCrypto :role="role" :cash="buyCredit" :wage="state.userInfo.fee" :marketclick="marketclick" />
        </div>
    </div>
</template>

<script>
import BuyCrypto from './BuyCrypto.vue';
import SellCrypto from './SellCrypto.vue';
import {tradeTo,tradeFrom} from "@/library/reuseableFunction";
// import {tradeTo, tradeFrom, precision, Loop} from "@/library/reuseableFunction";
    export default {
  components: { 
                BuyCrypto,
                SellCrypto,
                        },
        name:'Buysell',
        data () {
            return{
                marketclick: true,
                sellCredit: 0,
                buyCredit: 0,
                role:{},
            }
        },
        computed:{
            tradeTo,tradeFrom,
        },
        methods: {
            changeMarket(val){
                this.marketclick=val;
            },
            async getWalletCredit() {
                if (this.state.userInfo.authorized) {
                    const res = await this.$axios.get('/wallets/customer-addresses', {
                        params: {
                            coins: `${this.tradeFrom},${this.tradeTo}`,
                            walletType:'P2P'
                        }
                    })
                    this.buyCredit = res[this.tradeTo].credit || 0
                    this.sellCredit = res[this.tradeFrom].credit || 0
                }
                const coins = await this.$axios.get('/coins')
                this.role = coins?.content?.find((a) => a.coin === this.tradeFrom)
            },
            input(event) {
                console.log(event.target.value);
            },
        },
        created() {
            this.getWalletCredit()
        }
    }
</script>

<style lang="scss" scoped>
.date{
    width: 100%;
    span{
        label{
            input{
                &::placeholder{
                    font-family: 'Yekan-en' !important;
                }
                font-family: 'Yekan-en' !important;
            }
        }
    }
}
.showRange{
    align-self:center ;
}
.btnsell{
    background: var(--red-color);
    color: white;
    width: 100%;
}
.btnbuy{
       background: var(--positive-color);
    color: white;
    width: 100%; 
}
.numbers{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    text-align: end;
    .fee{
        display: flex;
        flex-direction: row;   
        column-gap: 5px;     
    }
}
.total{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    color: rgba(63, 63, 63, .7);
    font-weight: 300;
    font-size: clamp(10px,2vw,13px);
    a{
        transform: none;
        color: rgba(0, 119, 204, 1);
        font-weight: 300;
        font-size: clamp(10px,1vw,13px);
    }
}
.spans{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    height: 1px;
    left: 7px;
    width: 96.3%;
    bottom: 32px;
    cursor: default;
    position: absolute;
    border-radius: 100px;
    span{
        &:hover{
            
            cursor: pointer;
        }
    }
    // z-index: -3;
    // background: var(--secondary-color);
    border-radius: 100px;
}
.range{
        position: relative;
    datalist,option{
        opacity: 0;
    }
   transform: rotate(180deg);
   input{
       padding: 0;
   }
}
.inputs{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
}
.focus{
               transform: translateY(-20px) scale(0.8,0.8);
        background: white;
        z-index: 1;   
    }
        label{
    position: relative;
    span {
    transition: 1s;
        z-index: -1;
        color: var(--secondary-color);
        position: absolute;
        bottom: 5px;
        right: 16px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
}
input{
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 0.4px solid #A4C6DE;
    width: 100%;
    border-radius: 4px;
    // max-width: 266px;
    max-height: 36px;
}
.remain{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    color: rgba(63, 63, 63, .7);
    font-weight: 300;
    font-size: clamp(10px,2vw,13px);
}
.opration{
    display: flex;
    flex-direction: row;
    width: 100%;
    .sell{
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        width: 50%;
        box-sizing: border-box;
        padding: 8px 16px;
    }
}
.buysell{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
button{
    width: 50%;
        font-weight: 700;
    font-size: clamp(10px,1vw,14px);
    color: rgba(164, 198, 222, 1);
    &:hover{
        cursor: pointer;
    }
}
@media only screen and(max-width:550px){
    .spans{
        padding: 0 4px;
    }
    .total{

        flex-direction: column;
        align-items: flex-start;
    }
    .remain{
        flex-direction: column;
        align-items: flex-start;
    }
    .numbers{
        p{
            text-align: start;
        }
    }
}
@media only screen and(max-width:400px){
    .sell{
        padding: 8px !important;
    }
    .fee{
        flex-direction: column !important;
    }
}
</style>