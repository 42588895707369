<template>
    <div class="account">
        <form @submit.prevent="submit" class="account-container">
            <Personalinfo :blanckspace="blanckspace" :data.sync="userInfo" />
            <Bankaccount :blanckspace="blanckspace" :key="change" :data.sync="userInfo" :error="duplicateBank" />
            <Validation :blanckspace="blanckspace" :data.sync="userInfo" />
            <!-- <div v-if="disabled" class="note">
                <img src="@/assets/icons/error.svg" alt="">
                <p class="error" v-if="disabled">{{disabled}}</p>
            </div> -->
            <!-- <button :disabled="disabled" class="form-btn">{{state.lang.account.submit}}</button> -->
            <button v-if="disabled" :disabled="!disable" class="form-btn">{{disabled}}</button>
            <button v-if="!disabled"  class="form-btn">{{$route.name=='Validation'?state.lang.account.submit:state.lang.account.edit}}</button>
        </form>
    </div>
</template>

<script>
const inputLabelFa = {
        'firstName': 'نام',
        'lastName': 'نام خانوادگی',
        'email': 'ایمیل',
        'birthDay': 'تاریخ تولد',
        'mobileNumber': 'شماره موبایل',
        'telephone': 'شماره ثابت',
        'address': 'آدرس',
        'image': 'عکس',
    }
const inputLabelEn = {
        'firstName': 'Name',
        'lastName': 'Family',
        'email': 'Email',
        'birthDay': 'BirthDay',
        'mobileNumber': 'Mobile Number',
        'telephone': 'Telephone',
        'address': 'Address',
        'image': 'Picture',
    }
import Bankaccount from './Bankaccount.vue'
import Personalinfo from './Personalinfo.vue'
import Validation from './Validation.vue'
    export default {
        name:'Account',
        components:{
            Personalinfo,
                Bankaccount,
                Validation
        }, 
             data () {
            return{
                date:'',
                disable:false,
                blanckspace : {
                    name : false,
                    birthday : false,
                    phonenum : false,
                    family : false,
                    idcode : false,
                    cartnumber:false,
                    shabanumber:false,
                    bankname:true,
                    cellnum : false,
                    addres : false,
                },
                userInfo: {
                    // authorized: this.state.userInfo.authorized,
                    // pending: this.state.userInfo.pending,
                    authenticationType: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    nationalCode: '',
                    birthDay: '',
                    mobileNumber: '',
                    telephone: '',
                    address: '',
                    bankAccounts: [{shebaCode: "", cardNumber: "", bankName: ""}],
                    image: '',
                    IMAGE_NEEDED:true,
                },
                change: 0,
                duplicateBank: '',
            }
        },
        
        computed: {
            disabled() {
                for (let key in this.userInfo) {
                    if (['firstName', 'lastName', 'email', 'nationalCode', 'birthDay', 'mobileNumber', 'telephone', 'bankAccounts', 'image','address'].includes(key)) {
                        if (key === 'bankAccounts') {
                            for (let data of this.userInfo.bankAccounts) {
                                if (!data.shebaCode || !data.cardNumber || data.cardNumber.replace(/-/g, '').length < 16) {
                                   return this.state.lan=='Fa' ?   'اطلاعات بانکی اشتباه است' : 'Wrong Bank Information'
                                    // return false;
                                }
                            }
                            /*} else if (key === 'address') {
                                // let re = /\d{10}/;
                                if (this.userInfo.address && this.userInfo.address.replace(/ /ig, '').length < 21 /!*|| !re.test(this.userInfo.address)*!/) {
                                    return 'آدرس الزامیست'
                                }*/
                        } else if (key === 'nationalCode') {
                            if (this.userInfo[key] < 10) {
                                return 'کدملی اشتباه است';
                                // return false;
                            }
                        } else if (!this.userInfo[key]) {
                            if(this.state.lan=='Fa'){
                                return inputLabelFa[key] + ' اجباریست'
                            }else{
                                return inputLabelEn[key] + ' Is Necessary'
                            }
                        }
                    }
                }
                return false
            },
        },
        methods: {
            test(){
                console.log(this.userInfo);
            },
            async check() {
                this.state.loading = true;
                const res = await this.$axios("/users/account",{params:{'image':true}})
                this.userInfo = res.baseDTO
                this.userInfo.bankAccounts = res.baseDTO.bankAccounts.length ?
                    res.baseDTO.bankAccounts
                    : [{shebaCode: "", cardNumber: "", bankName: ""}]
                this.change++
                if(this.state.userInfo.authenticationType=='AUTHORIZED' && this.$route.name=='Validation'){
                this.state.userInfo.authorized=true;
                console.log(this.state.userInfo.authorized);
                this.$router.push({name:'Dashboard'})
            }
            },
            removeDash(items) {
                for (let item of items) {
                    item.cardNumber = item.cardNumber.replace(/-/gi, '');
                }
            },
            bankCheck(items) {
                for (let item of items) {
                    if (!item.cardNumber || !item.shebaCode) {
                        this.$error('اطلاعات ناقص', "شماره شبا یا کارت بانکی را وارد نکرده اید");
                        return false;
                    } else {
                        return true;
                    }
                }
            },
            async submit() {
                this.state.loading = true;
                this.removeDash(this.userInfo.bankAccounts);
                let form_data = new FormData();

                for (let key in this.userInfo) {
                    if (key === 'image') {
                        if (typeof this.userInfo.image !== 'string') {
                            form_data.append("file", this.userInfo.image);
                        }
                    } else {
                        form_data.append(key, this.userInfo[key]);
                    }
                }

                form_data.append("bankAccountsString", JSON.stringify(this.userInfo.bankAccounts));

                if (this.bankCheck(this.userInfo.bankAccounts)) {
                    try {
                        let vm = this
                        const config = {
                            onUploadProgress: function (progressEvent) {
                                vm.state.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            }
                        }
                            const res = await this.$axios.post("/users/account", form_data, config)

                        this.state.userInfo.authenticationType = res.baseDTO.authenticationType
                        this.state.userInfo.email = res.baseDTO.email
                        this.state.userInfo.authorized = res.baseDTO.authenticationType === "AUTHORIZED"
                        this.state.userInfo.pending = res.baseDTO.authenticationType === "PENDING"
                        await this.$error('', 'اطلاعات با موفقیت ذخیره شد', 'success')
                        this.$getuserInfo()
                        this.$router.push({name:'FinoTech'});
                        // if (res.message.includes("need to be verified")) {
                        //     this.state.loading = true
                        //     await this.$axios(`/finotech/send-sms-to-customer?email=${this.userInfo.email}`)
                        //     this.$router.push('/fino-verifying')
                        // } else {
                        //     return true
                        // }
                    } catch (e) {
                        if (e.response && e.response.data.message.includes("ShebaCode or CardNumber Is For Another User")) {
                            this.$error('', ' شماره شبا یا کارت قبلا وارد شده است.')
                            this.duplicateBank = e.response.data.message
                        }
                    }
                }
            },
        },
        mounted(){
            
        },
        created() {
            this.check()
        }
    }
</script>

<style lang="scss" scoped>
.form-btn{
    width: 80%;
    align-self: center;
    background: rgba(51, 158, 0, 1);
    color: white;
    margin-top: 33px;
    cursor: pointer;
}
.account{
    box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
    border-radius: 4px;
    width: 100%;
    height: 98%;
    overflow: scroll;
}
.account-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px;
    row-gap: 16px;
    width: 100%;
}
</style>